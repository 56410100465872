import axios from 'axios';

import getConfig from '@/configs/global';

const config = getConfig();
const { publicRuntimeConfig } = config;

const { API_URL } = publicRuntimeConfig;

const createAxiosInstance = () => {
  const validateStatus = status => status < 500;

  return axios.create({
    baseURL: API_URL,
    validateStatus,
  });
};

const api = {
  instance: createAxiosInstance(),
  updateToken: token => {
    api.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return api;
  },
  getOpportunities: async () => {
    return api.instance.get('/opportunities');
  },
  getOpportunity: async id => api.instance.get(`/opportunities/${id}`),
  createToken: async payload => api.instance.post('/tokens', payload),
  createAccountCreationToken: async payload =>
    api.instance.post('/tokens/account', payload),
  checkToken: async token =>
    api.instance.get('/tokens/info?type=token', {
      headers: { Authorization: `Bearer ${token}` },
    }),
  purge: () => {
    api.instance = createAxiosInstance();
    localStorage.removeItem('token');
    return api;
  },
  removeToken: () => {
    delete api.instance.defaults.headers.Authorization;
    localStorage.removeItem('token');
  },
  createAccount: async options => api.instance.post('/accounts', options),
  updateAccount: async (id, options) =>
    api.instance.patch(`/accounts/${id}`, options),
  createContact: async options => api.instance.post('/contacts', options),
  finalizeAccount: async options =>
    api.instance.post('/accounts/finalize', options),
  updateContact: async (id, options) =>
    api.instance.patch(`/contacts/${id}`, options),
  getContactMe: async () => api.instance.get('/contacts/me'),
  describeAccount: async () => api.instance.get('/accounts/describe'),
  getContactInfo: async (accountId, contactId) =>
    api.instance.get('/contacts/info', {
      params: {
        accountId,
        contactId,
      },
    }),
  getAccount: async accountId => api.instance.get(`/accounts/${accountId}`),
  getAccounts: async () => api.instance.get('/accounts'),
  subscribeNewsletter: async email =>
    api.instance.post('/newsletter', { email }),
  getCompanySuggestions: async term =>
    api.instance.get('/company-suggestions', { params: { term: `${term}*` } }),
  rentalRequests: async payload =>
    api.instance.post('/rental-requests', payload),
  purchaseRequests: async payload =>
    api.instance.post('/purchase-requests', payload),
  updateOpportunityLineItemsReleaseDate: async payload =>
    api.instance.patch('/opportunity-line-items', payload),
  validateIban: async iban =>
    axios.get(
      `https://openiban.com/validate/${iban}?getBIC=true&validateBankCode=true`
    ),
  sendEmail: async payload =>
    api.instance.post('/contact/rental-request', payload),
  getQuote: async quoteId => api.instance.get(`/quotes/${quoteId}`),
  acceptQuote: async quoteId => api.instance.post(`/quotes/${quoteId}/accept`),
  declineQuote: async (quoteId, payload) =>
    api.instance.post(`/quotes/${quoteId}/decline`, payload),
  getCountryCodes: async () => api.instance.get(`/country-codes`),
  getUsers: async () => api.instance.get(`/users`),
  getRentalRequestJobStatus: async (jobId, options) =>
    api.instance.get(`/jobs/rental-requests/${jobId}`, options),
  describeQuote: async () => api.instance.get('/quotes/describe'),
};

if (typeof window !== 'undefined') {
  window.api = api;
}

export default api;
