import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { getLocalStorageItem } from '@/helpers/localStorage';
import { filterEmptyProperties } from '@/helpers/utils';

export const SourceContext = createContext();

const initialSourceData = {
  medium: '',
  gclid: '',
  msclkid: '',
  leadReference: '/',
  utmParams: { utmSource: '', utmMedium: '', utmCampaign: '', utmKeyword: '' },
};

export const SourceProvider = ({ children }) => {
  const [source, setSource] = useState(initialSourceData);

  const setMedium = useCallback(
    medium =>
      setSource(prev => ({
        ...prev,
        medium,
      })),
    []
  );

  const setGclid = useCallback(
    gclid =>
      setSource(prev => ({
        ...prev,
        gclid,
      })),
    []
  );

  const setMsclkid = useCallback(
    msclkid =>
      setSource(prev => ({
        ...prev,
        msclkid,
      })),
    []
  );

  const setLeadReference = useCallback(
    leadReference =>
      setSource(prev => ({
        ...prev,
        leadReference,
      })),
    []
  );

  const setUtmParameters = useCallback(utmParams => {
    setSource(prev => ({
      ...prev,
      utmParams: {
        ...prev.utmParams,
        ...filterEmptyProperties(utmParams),
      },
    }));
  }, []);

  const value = useMemo(
    () => ({
      source,
      setMedium,
      setGclid,
      setMsclkid,
      setLeadReference,
      setUtmParameters,
    }),
    [
      source,
      setMedium,
      setGclid,
      setMsclkid,
      setLeadReference,
      setUtmParameters,
    ]
  );

  useEffect(() => {
    const setupSource = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const gclid =
        urlParams.get('gclid') || getLocalStorageItem(LOCAL_STORAGE_KEYS.GCLID);
      const msclkid =
        urlParams.get('msclkid') ||
        getLocalStorageItem(LOCAL_STORAGE_KEYS.MSCLKID);
      const utmSource =
        urlParams.get('utm_source') ||
        getLocalStorageItem(LOCAL_STORAGE_KEYS.UTM_SOURCE);
      const utmMedium =
        urlParams.get('utm_medium') ||
        getLocalStorageItem(LOCAL_STORAGE_KEYS.UTM_MEDIUM);
      const utmCampaign =
        urlParams.get('utm_campaign') ||
        getLocalStorageItem(LOCAL_STORAGE_KEYS.UTM_CAMPAIGN);
      const utmKeyword =
        urlParams.get('utm_keyword') ||
        getLocalStorageItem(LOCAL_STORAGE_KEYS.UTM_KEYWORD);
      const { referrer } = document;

      let medium = '';

      if (gclid) {
        medium = 'Google Ads';
        setGclid(gclid);
      } else if (msclkid) {
        medium = 'Microsoft Ads';
        setMsclkid(msclkid);
      } else if (referrer) {
        const { hostname } = new URL(referrer);
        const isGoogle = hostname.includes('.google.');
        medium = isGoogle ? 'Google Organic' : 'Referral';
      } else {
        medium = 'Direct';
      }

      setMedium(medium);
      setUtmParameters({ utmSource, utmMedium, utmCampaign, utmKeyword });
    };

    setupSource();
    // setupSource only on mount of the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SourceContext.Provider value={value}>{children}</SourceContext.Provider>
  );
};
