import parsePhoneNumber from 'libphonenumber-js/max';

export const formatPhone = (phone, countryCode) => {
  if (phone && countryCode) {
    const phoneNumber = parsePhoneNumber(phone, countryCode);
    return phoneNumber.number;
  }
  return '';
};

export const getPersonalData = fields => {
  const {
    prefix,
    name,
    email,
    surname,
    phone,
    countryCode,
    company,
    contactPerson,
    contactPersonPhone,
    contactPersonCountryCode,
  } = fields;
  return {
    forename: name,
    email,
    surname,
    prefix,
    company,
    phone: formatPhone(phone, countryCode),
    contactName: contactPerson,
    contactPhone: formatPhone(contactPersonPhone, contactPersonCountryCode),
  };
};
