import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { setLocalStorageItem } from '@/helpers/localStorage';

const paramsToSave = {
  gclid: LOCAL_STORAGE_KEYS.GCLID,
  msclkid: LOCAL_STORAGE_KEYS.MSCLKID,
  utm_source: LOCAL_STORAGE_KEYS.UTM_SOURCE,
  utm_medium: LOCAL_STORAGE_KEYS.UTM_MEDIUM,
  utm_campaign: LOCAL_STORAGE_KEYS.UTM_CAMPAIGN,
  utm_keyword: LOCAL_STORAGE_KEYS.UTM_KEYWORD,
  voucher: LOCAL_STORAGE_KEYS.VOUCHER,
};

const useSaveQueryParamsInLocalStorage = () => {
  const router = useRouter();

  useEffect(() => {
    const { query } = router;

    Object.entries(paramsToSave).forEach(([param, storageKey]) => {
      const value = query[param];
      if (value) {
        setLocalStorageItem(storageKey, value, {
          shouldExpire: true,
        });
      }
    });
  }, []);
};

export default useSaveQueryParamsInLocalStorage;
