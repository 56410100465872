import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { trackAddToBasket } from '@/helpers/dataLayer';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/helpers/localStorage';
import { useSourceContext } from '@/hooks/useSourceContext';

import { fetchAccessories, fetchQuestions } from './basketContextUtils';

export const BasketContext = createContext();

/* Request shape can be found in @/constants/propTypes */

export const BasketProvider = ({ children }) => {
  const [requests, setRequests] = useState({});
  const [stateHydrated, setStateHydrated] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState([]);
  // resetId change forces basket form state to update to current requests state
  const [resetId, setResetId] = useState();

  const { source } = useSourceContext();

  const setStoredBasketData = requests => {
    const currentStorageState = getLocalStorageItem(LOCAL_STORAGE_KEYS.BASKET);

    if (currentStorageState !== JSON.stringify(requests)) {
      setLocalStorageItem(LOCAL_STORAGE_KEYS.BASKET, requests);
    }
  };

  const getStoredBasketData = useCallback(() => {
    const storedData = getLocalStorageItem(LOCAL_STORAGE_KEYS.BASKET);
    let parsedData = {};
    if (storedData) {
      Object.entries(storedData).forEach(([key, value]) => {
        parsedData[key] = {
          ...value,
          dateFrom: isNaN(Date.parse(value.dateFrom))
            ? null
            : new Date(value.dateFrom),
          dateTo: isNaN(Date.parse(value.dateTo))
            ? null
            : new Date(value.dateTo),
        };
      });
    }
    return parsedData;
  }, []);

  const getMachineAccessoriesAndQuestions = async ({
    productReferenceId,
    productGroupId,
    fetchAccessoriesParams,
  }) => {
    let accessories = null;
    let questions = null;

    const accessoriesPromise = productReferenceId
      ? fetchAccessories(productReferenceId, fetchAccessoriesParams)
      : Promise.resolve(null);
    const questionsPromise = productGroupId
      ? fetchQuestions(productGroupId)
      : Promise.resolve(null);

    const [accessoriesResult, questionsResult] = await Promise.allSettled([
      accessoriesPromise,
      questionsPromise,
    ]);

    if (accessoriesResult.status === 'fulfilled' && accessoriesResult.value) {
      accessories = accessoriesResult.value;
    }
    if (questionsResult.status === 'fulfilled' && questionsResult.value) {
      questions = questionsResult.value;
    }

    return { accessories, questions };
  };

  const addRequestToBasket = useCallback(
    async request => {
      trackAddToBasket([request], source.leadReference);

      const requestId = uuidv4();
      const { productReferenceId, productGroupId } = request.rentalObject;
      const newRequest = {
        id: requestId,
        accessories: [],
        questions: [],
        dateFrom: null,
        dateTo: null,
        description: '',
        ...request,
      };

      // set initial request data without fetching accessories or questions
      // so there is no delay in showing data in the basket overlay
      setRequests(prev => {
        const requests = {
          ...prev,
          [requestId]: { ...newRequest },
        };

        setStoredBasketData(requests);
        return requests;
      });
      setResetId(uuidv4());
      setLoadingRequests(prev => [...prev, requestId]);

      const {
        accessories: loadedAccessories,
        questions: loadedQuestions,
      } = await getMachineAccessoriesAndQuestions({
        productReferenceId,
        productGroupId,
        fetchAccessoriesParams: { include: ['included', 'standard'] },
      });

      if (loadedAccessories || loadedQuestions) {
        setRequests(prev => {
          const request = {
            ...newRequest,
            accessories: loadedAccessories ?? newRequest.accessories,
            questions: loadedQuestions ?? newRequest.questions,
          };
          request.accessories.forEach(accessory => {
            if (accessory.itemData.invisible || accessory.itemData.included)
              accessory.count = newRequest.count;
          });
          const newRequests = {
            ...prev,
            [requestId]: request,
          };

          setStoredBasketData(newRequests);
          return newRequests;
        });
        setResetId(uuidv4());
      }
      setLoadingRequests(prev => prev.filter(id => id !== requestId));
    },
    [source.leadReference]
  );

  const getRentAgainRequestData = useCallback(
    async ({ machine, accessories: rentAgainAccessories }) => {
      const requestId = uuidv4();
      const { productReferenceId, productGroupId } = machine.rentalObject;
      let newRequest = {
        ...machine,
        id: requestId,
        accessories: [],
        questions: [],
      };

      const {
        accessories,
        questions: loadedQuestions,
      } = await getMachineAccessoriesAndQuestions({
        productReferenceId,
        productGroupId,
      });

      const loadedAccessories = accessories
        ?.map(accessory => {
          const rentAgainAccessoryData = rentAgainAccessories.find(
            ({ salesforceId }) =>
              salesforceId === accessory.itemData.salesforceId
          );
          const accessoryIsStandardOrIncluded =
            accessory.itemData.standard || accessory.itemData.included;

          if (rentAgainAccessoryData) {
            return {
              ...accessory,
              count: accessory.itemData.invisible
                ? machine.count
                : rentAgainAccessoryData.count,
              ...(accessoryIsStandardOrIncluded ? {} : { addedByUser: true }),
            };
          } else if (accessoryIsStandardOrIncluded) {
            if (accessory.itemData.included) {
              accessory.count = machine.count;
            }
            return accessory;
          }
        })
        .filter(accessory => !!accessory);

      if (loadedAccessories || loadedQuestions) {
        newRequest = {
          ...newRequest,
          accessories: loadedAccessories ?? newRequest.accessories,
          questions: loadedQuestions ?? newRequest.questions,
        };
      }
      return newRequest;
    },
    []
  );

  // function to add items that are rented again through Customer's Portal
  const addRentAgainRequestsToBasket = useCallback(
    async items => {
      const promises = await Promise.allSettled(
        items.map(item => getRentAgainRequestData(item))
      );

      const itemsToAddToBasket = promises
        .filter(({ status }) => status === 'fulfilled')
        .map(({ value }) => value);

      setRequests(prev => {
        const requests = {
          ...prev,
        };
        itemsToAddToBasket.forEach(item => {
          requests[item.id] = item;
        });
        setStoredBasketData(requests);
        return requests;
      });

      trackAddToBasket(itemsToAddToBasket, source.leadReference);
      setResetId(uuidv4());
    },
    [getRentAgainRequestData, source.leadReference]
  );

  const removeRequestFromBasket = useCallback(id => {
    setRequests(prev => {
      const newRequests = { ...prev };
      delete newRequests[id];

      setStoredBasketData(newRequests);

      return newRequests;
    });
  }, []);

  const updateRequestInBasket = useCallback((requestId, data) => {
    setRequests(prev => {
      const newRequests = {
        ...prev,
        [requestId]: { ...prev[requestId], ...data },
      };

      setStoredBasketData(newRequests);

      return newRequests;
    });
  }, []);

  const emptyBasket = useCallback(() => {
    setRequests({});
    setLocalStorageItem(LOCAL_STORAGE_KEYS.BASKET, {});
  }, []);

  const machineAlreadyInBasket = useCallback(
    machine =>
      !!Object.values(requests).find(
        ({ rentalObject: { salesforceId, name } }) =>
          (machine.salesforceId && salesforceId === machine.salesforceId) ||
          name === machine.name
      ),
    [requests]
  );

  useEffect(() => {
    const hydrateBasketState = () => {
      setRequests(getStoredBasketData());
      setStateHydrated(true);
    };
    hydrateBasketState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setRequests(getStoredBasketData());
        setResetId(uuidv4());
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () =>
      window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [getStoredBasketData]);

  const value = useMemo(
    () => ({
      requestsInBasket: Object.values(requests),
      requestsInBasketCount: Object.keys(requests).length,
      basketStateHydrated: stateHydrated,
      resetBasketFormId: resetId,
      basketDataLoading: loadingRequests.length > 0,
      addRequestToBasket,
      addRentAgainRequestsToBasket,
      removeRequestFromBasket,
      updateRequestInBasket,
      emptyBasket,
      machineAlreadyInBasket,
    }),
    [
      requests,
      stateHydrated,
      resetId,
      loadingRequests,
      addRequestToBasket,
      addRentAgainRequestsToBasket,
      removeRequestFromBasket,
      updateRequestInBasket,
      emptyBasket,
      machineAlreadyInBasket,
    ]
  );

  return (
    <BasketContext.Provider value={value}>{children}</BasketContext.Provider>
  );
};
