import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { pageView } from '@/helpers/dataLayer';

const sanitizePath = urlPath => {
  if (typeof window !== 'undefined' && urlPath) {
    const url = new URL(urlPath, window.location.origin);
    const sensitiveParams = ['token', 'accountId', 'contactId'];
    sensitiveParams.forEach(param => url.searchParams.delete(param));
    return window.location.origin + url.pathname + url.search + url.hash;
  }

  return '';
};

function usePageView() {
  const { asPath } = useRouter();
  const previousUrlRef = useRef();
  const sanitizedUrl = sanitizePath(asPath);

  useEffect(() => {
    if (previousUrlRef.current !== sanitizedUrl) {
      pageView(
        sanitizedUrl,
        previousUrlRef.current === undefined
          ? document.referrer
          : previousUrlRef.current
      );
      previousUrlRef.current = sanitizedUrl;
    }
  }, [sanitizedUrl]);
}

export default usePageView;
