export const LOCAL_STORAGE_KEYS = {
  B2B_MODAL_CLOSED: 'B2BModal:isClosed',
  CONTACT_PERSON_ID: 'ContactPersonId',
  TOKEN: 'token',
  ORDER_DETAILS: 'orderDetails',
  BASKET: 'basket',
  VOUCHER: 'voucher',
  GCLID: 'gclid',
  MSCLKID: 'msclkid',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_KEYWORD: 'utm_keyword',
};
