import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de';

export const LocalizationProvider = ({ children }) => {
  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      {children}
    </MuiLocalizationProvider>
  );
};
