import {
  format as formatDate,
  isValid as isDateValid,
  max as getLatestDate,
  min as getEarliestDate,
} from 'date-fns';
import { isUndefined, omitBy } from 'lodash';

import { formatPhone } from '@/helpers/mappers/personalData';

export const computeDate = date => {
  if (!isDateValid(date)) {
    return null;
  }
  return formatDate(date, 'yyyy-MM-dd');
};

const getFullAddress = (
  { city, street, postalCode, country },
  separator = ', '
) => {
  return [street, [postalCode, city].filter(Boolean).join(' '), country?.label]
    .filter(Boolean)
    .join(separator);
};

export const getDeliveryAddress = ({ city, street, postalCode, country }) => {
  return {
    structured: true,
    street,
    postalCode: postalCode,
    city,
    country: country.label,
    plain: getFullAddress({ city, street, postalCode, country }),
  };
};

export const getDeliveryDates = products => {
  const fromDates = [];
  const toDates = [];
  products.forEach(({ dateFrom, dateTo }) => {
    fromDates.push(dateFrom);
    toDates.push(dateTo);
  });

  return {
    dateFrom: getEarliestDate(fromDates),
    dateTo: getLatestDate(toDates),
  };
};

export const getCompany = ({ crefo, company }) => {
  return {
    structured: !!crefo,
    name: crefo?.name || company,
    ...crefo,
  };
};

export const getContact = ({
  countryCode,
  phone,
  prefix,
  name,
  surname,
  email,
  marketingConsent,
}) => {
  return {
    phone: formatPhone(phone, countryCode),
    prefix,
    forename: name,
    surname,
    email,
    ...(marketingConsent !== null &&
      marketingConsent !== undefined && { marketingConsent }),
  };
};

export const getCompanyAnnotation = ({
  addNonCrefoCompany,
  nonCrefoCompanyName = '',
  address = {},
}) =>
  addNonCrefoCompany
    ? `Angegebene Firma: ${nonCrefoCompanyName}\n${getFullAddress(
        address,
        '\n'
      )}`
    : '';

export const getProducts = products =>
  products.map(
    ({
      name,
      salesforceId,
      automatedOffer,
      sendAutomatedOffer,
      minLeadTime,
      rentalPriceBasis,
      rentalPrice,
      count,
      accessories,
      dateFrom,
      dateTo,
      rentalHours,
    }) => {
      const rentalStartDate = computeDate(dateFrom);
      const rentalEndDate = computeDate(dateTo);

      const accessoriesOrEquipments = accessories
        .filter(({ count, name }) => count > 0 && name)
        .map(
          ({
            count,
            name,
            salesforceId,
            automatedOffer,
            sendAutomatedOffer,
            minLeadTime,
            rentalPriceBasis,
            rentalPrice,
            included,
          }) => {
            return omitBy(
              {
                rentalStartDate,
                rentalEndDate,
                count,
                name,
                automatedOffer,
                sendAutomatedOffer,
                minLeadTime,
                rentalPriceBasis,
                rentalPrice,
                included,
                salesforceId,
              },
              isUndefined
            );
          }
        );

      return omitBy(
        {
          rentalStartDate,
          rentalEndDate,
          count,
          name,
          automatedOffer,
          sendAutomatedOffer,
          minLeadTime,
          salesforceId,
          rentalPrice,
          rentalPriceBasis,
          ...(rentalHours && { rentalHours }),
          ...(accessoriesOrEquipments.length && { accessoriesOrEquipments }),
        },
        isUndefined
      );
    }
  );

export const getRentalObject = products => {
  const someWithoutSalesforceId = products.some(
    ({ salesforceId }) => typeof salesforceId !== 'string'
  );
  return products
    .map(({ dateFrom, dateTo, accessories, count, name }) => {
      const stringifiedAccessories = accessories
        .map(part =>
          part.count > 0 && part.name ? `, ${part.count}x ${part.name}` : ''
        )
        .join('');

      const dates = ` (${formatDate(dateFrom, 'dd-MM-yyyy')} - ${formatDate(
        dateTo,
        'dd-MM-yyyy'
      )})`;

      return `${count}x ${name}${stringifiedAccessories}${
        someWithoutSalesforceId ? dates : ''
      }`;
    })
    .join('\n');
};
